import { Override } from "framer"

export function setUTMToInputs(): Override {
    return {
        onMount() {
            const urlParams = new URLSearchParams(window.location.search)
            const utmSource = urlParams.get("utm_source")
            const utmMedium = urlParams.get("utm_medium")
            const utmCampaign = urlParams.get("utm_campaign")
            const utmTerm = urlParams.get("utm_term")
            const utmContent = urlParams.get("utm_content")
            console.log(utmSource, utmMedium, utmCampaign, utmTerm, utmContent)

            if (utmSource) {
                const sourceInput = document.querySelector(
                    'input[name="utm_source"]'
                )
                console.log(sourceInput)
                if (sourceInput) {
                    ;(sourceInput as HTMLInputElement).value = utmSource
                    console.log(sourceInput)
                }
            }

            if (utmMedium) {
                const mediumInput = document.querySelector(
                    'input[name="utm_medium"]'
                )
                if (mediumInput)
                    (mediumInput as HTMLInputElement).value = utmMedium
            }

            if (utmCampaign) {
                const campaignInput = document.querySelector(
                    'input[name="utm_campaign"]'
                )
                if (campaignInput)
                    (campaignInput as HTMLInputElement).value = utmCampaign
            }

            if (utmTerm) {
                const termInput = document.querySelector(
                    'input[name="utm_term"]'
                )
                if (termInput) (termInput as HTMLInputElement).value = utmTerm
            }

            if (utmContent) {
                const contentInput = document.querySelector(
                    'input[name="utm_content"]'
                )
                if (contentInput)
                    (contentInput as HTMLInputElement).value = utmContent
            }
        },
    }
}
